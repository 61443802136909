.button--civicrm .button--social__icon {
  padding: 4px;
}
.button--civicrm .button--social__icon .icon {
  display: inline-block !important;
  background: white;
  width: 36px;
  height: auto;
  padding: 2px;
  border-radius: 3px;
}