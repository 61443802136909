.button--civicrm {
  .button--social__icon {
    padding: 4px;

    .icon {
      display: inline-block !important;
      background: white;
      width: 36px;
      height: auto;
      padding: 2px;
      border-radius: 3px;
    }
  }
}
